import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TextField,
  TableHead,
  TableCell,
  IconButton,
  TableContainer,
  Button,
} from '@material-ui/core';
import {Alert, Skeleton} from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {Typography} from '@mui/material';

import {useTheme} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {StripedList} from 'pages/Lead/components';
import {errorResponse} from 'shared/utils/errorResponse';
import {ModalEditPayment} from './Modal';

import {IPaymentInfo, LeadService} from 'shared/services/api/LeadService';

export const Payment: React.FC<{idLead: string}> = ({idLead}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>({
    situacaoLinkPagamentoTaxa: 1,
  } as IPaymentInfo);

  const [hasError, setHasError] = useState(false);

  const {theme} = useTheme();

  const isPaid = useMemo(
    () => paymentInfo.situacaoLinkPagamentoTaxa === 2,
    [paymentInfo.situacaoLinkPagamentoTaxa],
  );

  const isPending = useMemo(
    () => paymentInfo.situacaoLinkPagamentoTaxa === 1,
    [paymentInfo.situacaoLinkPagamentoTaxa],
  );

  const handleTitleVerifier = (situation: number) => {
    switch (situation) {
      case 1:
        return 'Pendente';
      case 2:
        return 'Realizado';
      case 3:
        return 'Vencido';
    }
  };

  const handleGetLeadPaymentInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await LeadService.getPaymentInfoByLeadId(idLead);
      setPaymentInfo(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');

      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [idLead]);

  const handlePostGenerateLink = useCallback(async () => {
    setIsLoading(true);
    try {
      await LeadService.postGeneratePaymentLinkByLeadId(idLead);

      handleGetLeadPaymentInfo();
    } catch (error) {
      feedback(errorResponse(error), 'error');

      setIsLoading(false);
    }
  }, [handleGetLeadPaymentInfo, idLead]);

  const handleSendEmail = useCallback(async () => {
    feedback('Carregando...', 'loading');
    try {
      await LeadService.postSendPaymentEmailByLeadId(idLead);
      feedback('E-mail enviado', 'success');
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [idLead]);

  useEffect(() => {
    if (!isOpenModal) {
      handleGetLeadPaymentInfo();
    }
  }, [idLead, isOpenModal]);

  if (isLoading) {
    return (
      <Grid xs>
        <Box marginTop={2}>
          <Skeleton variant="rect" width={'100%'} height={500} />
        </Box>
      </Grid>
    );
  }

  return (
    <>
      {hasError ? (
        <>
          <Box display="flex">
            <Alert icon={false} severity="warning">
              Link de pagamento não foi gerado corretamente.
            </Alert>
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={handlePostGenerateLink}>
              Gerar link de pagamento
            </Button>
          </Box>
        </>
      ) : (
        <Grid xs={12}>
          {isPending && (
            <>
              <Box marginBottom={2}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="10%">E-mail</TableCell>
                        <TableCell width="10%">Copiar</TableCell>
                        <TableCell width="100%">URL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={11}>
                        <TableCell>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'start'}
                            paddingLeft={1}>
                            <IconButton>
                              <EmailIcon
                                cursor={'pointer'}
                                color="primary"
                                onClick={handleSendEmail}
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'start'}
                            paddingLeft={1}>
                            <IconButton>
                              <FileCopyIcon
                                width={'100px'}
                                cursor={'pointer'}
                                color="primary"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    paymentInfo?.linkPagamento || '',
                                  );
                                  feedback(
                                    'URL copiada para sua área de transferência!',
                                    'success',
                                  );
                                }}
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            fullWidth
                            size="small"
                            value={paymentInfo?.linkPagamento}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}

          {!isPaid && (
            <ModalEditPayment
              idLead={idLead}
              isLoading={isLoading}
              onClose={() => setIsOpenModal(false)}
              openModal={isOpenModal}
            />
          )}

          <Box>
            <TableContainer
              component={Paper}
              elevation={3}
              style={{overflow: 'hidden'}}>
              <Box
                paddingX={2}
                marginTop={2}
                marginBottom={4}
                display={'flex'}
                justifyContent={'space-between'}>
                <Typography
                  variant="h4"
                  fontWeight={600}
                  color={
                    paymentInfo?.situacaoLinkPagamentoTaxa === 2
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                  }>
                  {`Pagamento ${handleTitleVerifier(
                    paymentInfo?.situacaoLinkPagamentoTaxa,
                  )}`}
                </Typography>
                {!isPaid && (
                  <IconButton onClick={() => setIsOpenModal(true)}>
                    <EditIcon color="primary" />
                  </IconButton>
                )}
              </Box>

              <Grid container xs={12}>
                <StripedList paymentInfo={paymentInfo} />
              </Grid>
            </TableContainer>
          </Box>
        </Grid>
      )}
    </>
  );
};
