import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

interface IParametro {
  parametro: string;
  valor: string;
}

export interface IOrigemLead {
  idLeadOrigem: number;
  idTipoServicoPublicidade: number;
  parametrosLinkCustomizado: IParametro[] | null;
}

export interface ILead {
  nomeCampanha: string;
  email: string;
  nomeCandidato: string;
  telefone: string;
  ultimaIteracao: string;
  idMotivoPerda: string;
  id: string;
  estabelecimento: string;
  nomeLead: string;
  oferta: string;
  idSituacaoAtualNoFunil: string;
  situacaoAtualNoFunil: string;
  linhaDoTempo: ILinhaDoTempo[];
  origemLead: IOrigemLead;
  funil: ILeadFunil[];
  possuiTaxa: boolean;
}

export interface IOptions {
  id: string;
  nome: string;
  idEstabelecimento?: string;
  idCurso?: string;
  idCiclo?: string;
}

export interface IOffer {
  id: string;
  complementos1DaOferta: IOptions[];
  complementos2DaOferta: IOptions[];
  idEstabelecimento: string;
  idTurno: string | null;
  idCurso: string;
  idCiclo: string;
  nomeCiclo: string;
  nomeCurso: string;
  nomeTurno: string | null;
}

export interface IShift {
  id: string;
  idCurso: string;
  idCiclo: string;
  idEstabelecimento: string;
  nome: string;
}

export interface ICycle {
  id: string;
  idCurso: string;
  idEstabelecimento: string;
  nome: string;
}

export interface ICourse {
  id: string;
  idEstabelecimento: string;
  nome: string;
}

export interface IEstablishment {
  id: string;
  nome: string;
}

export interface IOptionField {
  id: number;
  nome: string;
  codigoParaIntegracao: string;
  ordem: number;
}

export interface IValidationFields {
  id: number;
  idCondicaoParaValidacao: number;
  nomeCondicaoParaValidacao: string | null;
  validacaoInicial: string;
  validacaoFinal: string;
  mensagem: string;
}

export interface IPortResponseOptions {
  id: string;
  valor: string;
}

export interface IResponseForm {
  id?: string;
  valor: string;
  valorChecado: boolean;
  idCampoDaFicha: string;
  nomeArquivo?: string;
  conteudoArquivo?: string;
  opcoes: IPortResponseOptions[];
}

export interface IFormFields {
  idCampo: string;
  nomeCampo: string;
  idTipoDeCampo: number;
  nomeTipoDeCampo: string;
  idCampoPredefinido: number;
  nomeCampoPredefinido: string | null;
  textoDeAjuda: string | null;
  obrigatorio: boolean;
  disponivelParaLead: boolean;
  idTipoMascara: number | null;
  mascara: string;
  ativo: boolean;
  opcoes: IOptionField[];
  validacoes: IValidationFields[];
  resposta?: IResponseForm;
  formatarTexto?: string;
}

export interface IForm {
  apresentarNoFormulario: boolean;
  campos: IFormFields[];
  idSecao: string;
  idSecaoPredefinida: number | null;
  maximoColunas: number;
  nomeSecao: string;
}

export interface IEditResponse {
  estabelecimentos: IEstablishment[];
  ciclos: ICycle[];
  cursos: ICourse[];
  turnos: IShift[];
  ofertas: IOffer[];
  personalizacao: null;
  secoes: IForm[];
  idCiclo: string;
  idCurso: string;
  idEstabelecimento: string;
  idTurno: string;
  idOferta: string;
  permiteEditar: boolean;
  textoTermoLgpd: string;
  dataHoraAceiteTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
  linkFormulario: string | null;
}

export interface ILeadFunil {
  ordem: number;
  situacao: string;
  finalizado: boolean;
  atual: boolean;
}

export interface ILinhaDoTempo {
  atendente: string;
  dataHora: string;
  situacao: string;
  descricao: string;
  tipo: string;
}

export interface IAtendimentosLead {
  atendimentos: IAtendimentos[];
  permiteEditar: string;
  permiteExcluir: string;
  permiteIncluir: string;
}

export interface IAtendimentos {
  atendimento: string;
  dataAgenda: string;
  dataAtendimento: string;
  idAtendente: number;
  idAtendimentoDoLead: string;
  idDisponibilidadeDeAgenda: null;
  idEntrevistador: null;
  idLead: string;
  idSituacaoDeAgenda: null;
  idSituacaoDoLead: string;
  idUsuarioEntrevistador: null;
  nomeAtendente: string;
  nomeEntrevistador: null;
  nomeSituacaoDeAgenda: null;
  nomeSituacaoDoLead: string;
  usaAgendamento: boolean;
}

export interface ITarefas {
  estaFechada: boolean;
  fechadaEm: string;
  id: string;
  nome: string;
  obrigatoria: boolean;
  venceEm: string;
}
export interface IMotivoPerda {
  id: number;
  descricao: string;
}

const getLeadContext = async () => {
  try {
    const {data} = await Api.get(`/ContextoUsuario`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLead = async (idLead: string): Promise<ILead> => {
  try {
    const {data} = await Api.get(`/Lead/${idLead}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getAtendimentos = async (idLead: string): Promise<IAtendimentosLead> => {
  try {
    const {data} = await Api.get(`/Lead/atendimento/${idLead}`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getTarefas = async (idLead: string): Promise<ITarefas[]> => {
  try {
    const {data} = await Api.get(`/lead/${idLead}/tarefas`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getSituacaoAgenda = async (): Promise<ITarefas[]> => {
  try {
    const {data} = await Api.get(`/Lead/atendimento/situacoesdeagenda`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postLeadLossReason = async (payload: any): Promise<IRequestResult> => {
  try {
    await Api.patch(`/Lead/MotivoPerda`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

const postNextStep = async (payload: any): Promise<IRequestResult> => {
  try {
    await Api.post(`/Lead/AvancarEtapa`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao avançar. ${error.data.data}`);
  }
};

const postSalvarTarefa = async (
  payload: any,
  idLead: string,
): Promise<IRequestResult> => {
  try {
    await Api.post(`/Lead/${idLead}/tarefas`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

const postFecharTarefa = async (
  payload: any,
  idTarefa: string,
): Promise<IRequestResult> => {
  try {
    await Api.patch(`/Lead/tarefas/${idTarefa}/fechar`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.data.data}`);
  }
};

const deletarTarefa = async (idTarefa: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Lead/tarefas/${idTarefa}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

const getInternalEditData = async (
  idCampaign: string,
  idLead: string,
): Promise<IEditResponse | undefined> => {
  try {
    const response = await Api.get(
      `/lead/FormularioComRespostas?idCampanha=${idCampaign}&idLead=${idLead}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Erro ao Carregar os dados');
  }
};

export const EditLeadService = {
  getLead,
  getTarefas,
  postNextStep,
  deletarTarefa,
  getLeadContext,
  postLeadLossReason,
  getAtendimentos,
  postSalvarTarefa,
  postFecharTarefa,
  getSituacaoAgenda,
  getInternalEditData,
};
